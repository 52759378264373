@import '_mixin';
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

// 
.bg-svg {
  height: 100vh !important;
  width: 100vw !important;
}

.ant-btn-primary {
  background-color: #6D8A63;
}